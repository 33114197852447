import * as React from 'react';
import type { PageProps } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutIndex = ({ location }: PageProps) => {
  return (
    <Layout location={location} title="About Us">
      <Seo title="About Us" />
      <p>Some paragraph text and location = {location.pathname}</p>
    </Layout>
  );
};

export default AboutIndex;
